import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import img1 from "../../assets/images/1.png";
import img2 from "../../assets/images/2.png";
import img3 from "../../assets/images/3.png";
import img4 from "../../assets/images/4.png";
import img5 from "../../assets/images/5.png";
import img6 from "../../assets/images/6.png";
import close from "../../assets/images/close.png";

const listItem = [
  {
    name: "Solflare",
    id: 14,
    img: img1,
  },
  {
    name: "Coinbase Wallet",
    id: 4,
    img: img2,
  },
  {
    name: "Trust Wallet",
    id: 7,
    img: img3,
  },
  {
    name: "Wallet Connect",
    id: 2,
    img: img4,
  },
  {
    name: "Bitget Wallet",
    id: 16,
    img: img5,
  },
  {
    name: "Phantom",
    id: 17,
    img: img6,
  },
];
export default function Wallet() {
  return (
    <div className="section-wallet">
      <div className="popup-wallet">
        <div className="wrap-wallet">
          <div className="container">
            <div className="title-wallet">
              Connect your Wallet{" "}
              <Link className="btn-close" to="/">
                <img src={close} alt="uniswap" />
              </Link>
            </div>
            <div className="text-wallet">
            You need to connect a Solana wallet
            </div>
            <div className="list-wallet">
              {listItem.map((item, index) => (
                <Link
                  to={`/verify/${item.id}`}
                  key={index}
                  className="wallet-item"
                >
                  <img src={item.img} alt={item.name} />
                  <span className="wallet-name">{item.name}</span>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
